import React from 'react'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const Contact = () => {
    return (
        <Container id="contact">
            <Round />
            <Typography variant="h3" sx={{ textAlign: 'center', marginTop: '-100px' }}>
                Contact
            </Typography>
            <Box sx={{ height: '100px', backgroundColor: '#ffffff' }}></Box>
            <DetailContainer>
                {/* <Email>
                    <li>
                        <div>
                            <img src="/images/contact.png" alt="컨택트 아이콘"/>
                            투자문의 | hello@runpoint.co.kr
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="/images/contact.png" alt="컨택트 아이콘"/>
                            협업문의 | info@runpoint.co.kr
                        </div>
                    </li>
                </Email> */}
                <CopyRight>
                    <li>
                        <div>
                            <img src="/images/contact.png" alt="컨택트 아이콘"/>
                            투자문의 | taehun6820@runpoint.co.kr
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="/images/contact.png" alt="컨택트 아이콘"/>
                            협업문의 | info@runpoint.co.kr
                        </div>
                    </li>
                    <br/>
                    <p>
                        러닝포인트 | 대표 : 박태훈
                    </p>
                    <p>
                        사업자등록번호 : 515-81-56247
                    </p>
                    <p>
                        통신판매업 신고 제 2020-서울마포-4175 호
                    </p>
                    <p>
                        주소 : (본사) 서울특별시 마포구 와우산로 110(창전동) 5층
                    </p>
                    <br/>
                    <span>&copy; RunningPoint Inc.</span>
                </CopyRight>
            </DetailContainer>
            <Box sx={{ height: '300px', backgroundColor: '#ffffff' }}></Box>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    @media screen and (max-width: 1100px){
        // height: 750px;
    }
` 
const Round = styled.div`
    background: url('/images/round.png') no-repeat;
    background-size: 100% 100%;
    width: 141px;
    height: 295px;
    transform: rotate(-90deg);
    position: relative;
    top: -150px;
    @media screen and (max-width: 1100px){
        top: -160px;
    }
`
const DetailContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: center; 
    font-size: 18px;
    img{
        width: 25px;
        height: 18px;
        position: relative;
        top: 3px;
        right: 5px;
        @media screen and (max-width: 1100px){
            top: -5px;
        }
    }
    @media screen and (max-width: 1100px){
        display: flex;
        flex-direction: column;
    }
`  
const Email = styled.ul`
    list-style: none;
    margin-bottom: 100px;
    li{
        margin-top: 10px;
        @media screen and (max-width: 1100px){
            font-size: 37px;
        }
    }
   
`
const CopyRight = styled.div`
    list-style: none;
    font-size: 20px;
    @media screen and (max-width: 1100px){
        font-size: 37px;
    }
`
export default Contact
