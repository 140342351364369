import React from 'react'
import styled from 'styled-components'

const Team = () => {
    return (
        <Container>
            <BlueLine />
            <Content id="team">
                <LineTop/>
                <Heading>Team</Heading>
                <RunLogo/>
                <Profile>
                    <tbody style={{ textAlign: 'center' }}>
                        <ProfileRow>
                            <td><ProfileImage src="/images/Park.png" alt="대표님" /></td>
                            <td><ProfileImage src="/images/Jeong.png" alt="상호님" /></td>
                            <td><ProfileImage src="/images/Suhyun2.jpg" alt="수현님" /></td>
                        </ProfileRow>
                        <tr>
                            <td><ul><li>Taehun Park</li><li>CEO</li></ul></td>
                            <td><ul><li>Sangho Jeong</li><li>Project Owner</li></ul></td>
                            <td><ul><li>Suhyun Park</li><li>Designer / Director</li></ul></td>
                        </tr>
                        <ProfileRow>
                            <td><ProfileImage src="/images/Kim.png" alt="건우님"/></td>
                            <td><ProfileImage src="/images/and_Jang.png" alt="혜성님" /></td>
                            <td><ProfileImage src="/images/Yeonsu.png" alt="연수님"/></td>
                        </ProfileRow>
                        <tr>
                            <td><ul><li>Geonwoo Kim</li><li>Back-End Developer</li></ul></td>
                            <td><ul><li>Hyeseong Jang</li><li>Android Developer</li></ul></td>
                            <td><ul><li>Yeonsu Lee</li><li>UI / UX Designer</li></ul></td>
                        </tr>
                    </tbody>
                </Profile>
                <Line />
            </Content>
        </Container>
    )
}

const Container = styled.div`
    width: 100%; 
    height: 2070px;
    @media screen and (max-width: 1100px){
        height: 2200px;
    }
`
const BlueLine = styled.div`
    width: 100%;
    height: 166px;
    background-color: rgba(0, 160, 233, 1);
`
const Content = styled.div`
    width: 100%; 
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
`
const Heading = styled.div`
    color: #ffffff;
    font-size: 40px;
    font-weight: bold;
    margin-top: -200px;
    margin-bottom: 100px;
    @media screen and (max-width: 1100px){
        font-size: 100px;
        margin-top: -220px;
    }
`
const RunLogo = styled.div`
    background: url('/images/logo_W.png') no-repeat;
    background-size: 100% 100%;
    width: 300px; 
    height: 125px;
`
const Profile = styled.table`
    border-spacing: 70px;
    color: #ffffff;
    ul{
        list-style: none;
        margin: -50px 0;
    }
    li:first-child{
        font-size: 20px;
        font-weight: bold;
    }
    li:last-child{
        font-size: 20px;
    }
`
const ProfileRow = styled.tr`
    td{
        width: 300px;
        height: 400px;
        }
`
const ProfileImage = styled.img`
    width: 100%;
`
const LineTop = styled.div`
    background: url('/images/fourth-line.png') no-repeat;
    background-size: 100% 100%;
    width: 8px;
    height: 487px;
    position: relative;
    top: -300px;
`
const Line = styled.div`
    background: url('/images/third-line.png') no-repeat;
    background-size: 100% 100%;
    width: 8px;
    height: 327px;
    transform: rotate(-28deg);
    position: relative;
    top: 20px;
    @media screen and (max-width: 1100px){
        top: 70px;
    }
`
export default Team
