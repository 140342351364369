import React from 'react';
import './App.css';
import { links } from './data'
import styled from 'styled-components'
import Home from './components/Home/Home'
import Product from './components/Product/Product'
import Partner from './components/Partner/Partner'
import Team from './components/Team/Team'
import Press from './components/Press/Press'
import Contact from './components/Contact/Contact'


function App() {
  return (
    <Container>
        <Navbar>
            {links.map((link) => {
            return(
                <Content href={link.url} key={link.id}>
                        <span>{link.text}</span>
                </Content>
            )
            })}
        </Navbar>
        <Home />
        <Product />
        <Partner />
        <Team />
        <Press />
        <Contact />
    </Container>
  );
}

const Navbar = styled.div`
    width: 900px;
    min-width: 1000px;
    height: 150px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    /* @media screen and (max-width: 768px){
        height: 50px;
    } */
    @media screen and (max-width: 1100px){
        justify-content: space-evenly;
    }

`
const Content = styled.a`
    display: inline-block;
    height: 30%;
    text-decoration: none;
    font-size: 25px;
    font-weight: bold;
    color: black;
    /* @media screen and (max-width: 768px){
        font-size: 20px;
    } */
    @media screen and (max-width: 1100px){
        font-size: 40px;
    }
    
`
const Container = styled.div`
    width: 100%;
    min-width: 1100px;
    @media screen and (max-width: 1100px){
        width: 100%;
        height: auto;
    }
    /* 패드사이즈 768~1100까지 나타낼 css */
    /* @media screen and (min-width: 768px) and (max-width: 1100px){
        width: 1100px;
        max-width: 1100px;

    } */
`
export default App;