import React from 'react'
import styled from 'styled-components'

const Home = () => {
    return (
        <Container id="home">
            <Introduction>
                <Title>내가 만드는 운동의 <span style={{color:"rgba(0, 160, 233, 1)"}}> &nbsp;새로운 가치</span></Title>
                <LogoImage />
                <FirstLine />
                <SubTitle>
                    <FirstSub>
                        <p>그동안 힘들고 접근하기 어려웠던 '마라톤'</p>
                        <p>마라톤은 이제 새로운 운동문화를 만들고 있습니다.</p>
                    </FirstSub>
                    <ScdSub>
                        <p>끈기, 함께, 취미, 용기, 열정, 기부</p>
                    </ScdSub>
                    <TrdSub>            
                        <LgSub><b>참가자들이 만드는 운동의 새로운 가치를 함께하는 것.</b></LgSub>
                        <p>이것이 러닝포인트가 걸어온 길이자 앞으로 뛰어갈 방향입니다.</p>
                    </TrdSub>
                </SubTitle>
            </Introduction>
            <WeAre>
                <BlueLine/>
                <Left>
                    <span>We are</span>
                    <SecondLine />
                </Left>
                <Right>
                    <FirstWe>
                        <p>FIRST</p>
                        <p>MARATHON</p>
                        <p>PLATFORM</p>
                    </FirstWe>
                    <SecondWe>
                        <p>러닝포인트는</p>
                        <p>마라톤 일정확인, 결제대행은 물론</p>
                        <p>대회 개최까지 언제 어디서든</p>
                        <p>마라톤대회 정보를 쉽게 확인 할 수 있는</p>
                        <p>국내 최초 마라톤 플랫폼 서비스를 제공합니다.</p>
                    </SecondWe>
                    <ThirdWe>
                        <p>이제 마라톤은 랭킹마라톤을 통해</p>
                        <p>누구나 편리하게 참가 할 수 있게 될 것입니다.</p>
                    </ThirdWe>
                </Right>
            </WeAre>

        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const Introduction = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 1070px;
`
const Title = styled.p`
    display: inline-flex;
    font-size: 30px;
    margin-top: 150px;
    word-spacing: 3px;
    @media screen and (max-width: 1100px){
        font-size: 37px;
    }
`
const LogoImage = styled.div`
    display: inline-flex;
    background: url('/images/runpoint-logo.png') no-repeat;
    background-size: 100% 100%;
    width: 376px;
    height: 157px;
    margin-top: 120px;
    @media screen and (max-width: 1100px){
    }
`
const FirstLine = styled.div`
    display: inline-flex;
    background: url('/images/first-line.png') no-repeat;
    background-size: 100% 100%;
    transform: rotate(90deg);
    width: 8px;
    height: 508px;
    margin-top: -200px;
`
const SubTitle = styled.div`
    margin-top: -100px;
    margin-bottom: 150px;
    
`
const FirstSub = styled.div`
    text-align: center;
    font-size: 22px;
    @media screen and (max-width: 1100px){
        font-size: 37px;
    }
    
`
const ScdSub = styled.div`
    margin-top: 20px;
    text-align: center;
    font-size: 22px;
    color: rgba(0, 160, 233, 1);
    @media screen and (max-width: 1100px){
        font-size: 37px;
    }
`
const TrdSub = styled.div`
    margin-top: 20px;
    text-align: center;
    font-size: 22px;
    @media screen and (max-width: 1100px){
        font-size: 37px;
    }
`
const LgSub = styled.p`
    font-size: 30px;
    @media screen and (max-width: 1100px){
        font-size: 39px;
    }
`
const WeAre = styled.div`
    width: 100%;
    height: 950px;
    display: flex;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 1);
    transition: all 1.2s ease-out;
`
const BlueLine = styled.div`
    width: 100%;
    height: 120px;
    background-color: rgba(0, 160, 233, 1);
    position: relative;
    top: 0;
`
const Left = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    span{
        color: white;
        font-size: 40px;
        margin: 100px 0;
        font-weight: bold;
        @media screen and (max-width: 1100px){
            font-size: 50px;
        }
    }
`
const SecondLine = styled.div`
    background: url('/images/second-line.png') no-repeat;
    background-size: 100% 100%;
    width: 8px;
    height: 762px;
    position: relative; 
    top: 0;
    transform: rotate(180deg);
`
const Right = styled.div`
    width: 50%;
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: left;
`
const FirstWe = styled.div`
    width: 274px;
    height: 166px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    transition: 1.2s;
    @media screen and (max-width: 1100px){
        font-size: 39px;
    }
`
const SecondWe = styled.div`
    font-size: 20px;
    color: white;
    @media screen and (max-width: 1100px){
        font-size: 27px;
    }
`
const ThirdWe = styled.div`
    font-size: 20px;
    color: white;
    @media screen and (max-width: 1100px){
        font-size: 27px;
    }
`
export default Home
