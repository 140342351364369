import React from 'react'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'


const Partner = () => {

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', transform: 'rotate(31deg)', marginTop: '-150px', marginBottom: '-150px' }}>
                <img src="/images/third-line.png" style={{ width: '8px', height: '300px'}}/>
            </Box>
            <Box sx={{ height: '300px', backgroundColor: 'rgba(239, 248, 252, 1)' }}></Box>
            <Typography variant="h3" sx={{ backgroundColor: 'rgba(239, 248, 252, 1)', textAlign: 'center' }} id="partner">
                Partner
            </Typography>
            <Box sx={{ height: '150px', backgroundColor: 'rgba(239, 248, 252, 1)' }}></Box>
            <Grid container sx={{ justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(239, 248, 252, 1)'}}>
                <Grid item xs={4} md={4} sm={4} sx={{ textAlign: 'center'}}>
                    <img src='/images/shinsegae.png' style={{ width: '200px', height: 'auto'}}/>
                </Grid>
                <Grid item xs={4} md={4} sm={4} sx={{ textAlign: 'center'}}>
                    <img src='/images/dongwon.png' style={{ width: '200px', height: 'auto'}}/>
                </Grid>
                <Grid item xs={4} md={4} sm={4} sx={{ textAlign: 'center'}}>
                    <img src='/images/samyang.png' style={{ width: '180px', height: 'auto'}}/>
                </Grid>
            </Grid>
            <MyDiv/>
            <Grid container sx={{ justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(239, 248, 252, 1)'}}>
                <Grid item xs={4} md={4} sm={4} sx={{ textAlign: 'center'}}>
                    <img src='/images/imo.png' style={{ width: '180px'}}/>
                </Grid>
                <Grid item xs={4} md={4} sm={4} sx={{ textAlign: 'center'}}>
                    <img src='/images/kellogg.png' style={{ width: '200px'}}/>
                </Grid>
                <Grid item xs={4} md={4} sm={4} sx={{ textAlign: 'center'}}>
                    <img src='/images/hanyul.png' style={{ width: '250px'}}/>
                </Grid>
            </Grid>
            <MyDiv/>
            <Grid container sx={{ justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(239, 248, 252, 1)'}}>
                <Grid item xs={4} md={4} sm={4} sx={{ textAlign: 'center'}}>
                    <img src='/images/donga.png' style={{ width: '250px'}}/>
                </Grid>
                <Grid item xs={4} md={4} sm={4} sx={{ textAlign: 'center'}}>
                    <img src='/images/sinhan.png' style={{ width: '200px'}}/>
                </Grid>
                <Grid item xs={4} md={4} sm={4} sx={{ textAlign: 'center'}}>
                    <img src='/images/warner.png' style={{ width: '100px'}}/>
                </Grid>
            </Grid>
            <Box sx={{ height: '300px', backgroundColor: 'rgba(239, 248, 252, 1)' }}></Box>
        </>
    )
}

const MyDiv = styled.div`
    background-color: rgba(239, 248, 252, 1);    
    height: 100px;

`
export default Partner
