export const links = [
    {
        id: 1,
        text: 'Home',
        url: '#home'
    },{
        id: 2,
        text: 'Product',
        url: '#product'
    },{
        id: 3,
        text: 'Partner',
        url: '#partner'
    },{
        id: 4,
        text: 'Team',
        url: '#team'
    },{
        id: 5,
        text: 'Contact',
        url: '#contact'
    }
]