import React from 'react'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const Press = () => {
    return (
        <Container>
            <Box sx={{ height: '300px', backgroundColor: 'rgba(239, 248, 252, 1)' }}></Box>
            <Typography variant="h3" sx={{ backgroundColor: 'rgba(239, 248, 252, 1)', textAlign: 'center' }}>
                Press
            </Typography>
            <Box sx={{ height: '100px', backgroundColor: 'rgba(239, 248, 252, 1)' }}></Box>
            <News>
                <Title onClick={() => window.open('https://www.gokorea.kr/news/articleView.html?idxno=724796', '_blank', 'noopener noreferrer')}>
                    동아제약과 애니멀런 6월 비대면 마라톤 ‘악어런’ 개최
                </Title>
                <Content>
                    벤처기업 러닝포인트는 동아제약과 협업해 2022 애니멀런 6월 악어런 비대면 마라톤 대회를 개최한다고 전했다.<br/>
                    이번 6월 악어런 대회는 멸종위기 동물들을 위해 매월 진행하는 기부 레이스로, 코로나19로 인해 비대면 방식으로 운영하는 러닝포인트의 대표적인 비대면 마라톤 대회...
                </Content>
                <From>
                    공감신문 · 2022.06.28
                </From>
            </News>
            <News>
                <Title onClick={() => window.open('https://www.nbntv.co.kr/news/articleView.html?idxno=979338', '_blank', 'noopener noreferrer')}>
                    신한은행 창립 40주년 기념 ‘참신한 홈런 비대면 마라톤’ 대회 개최
                </Title>
                <Content>
                    벤처기업 러닝포인트가 신한은행과 진행한 신한은행 창립 40주년 기념 2022 참신한 홈런 비대면 마라톤 대회가 성황리에 마무리 성료했다고 전했다.<br/>
                    참가자들에게는 기념 메달, 스티커, 마스크와 더불어 신한·KBO 40주년 기념 야구공...
                </Content>
                <From>
                    내외경제TV · 2022.06.27
                </From>
            </News>
            <News>
                <Title onClick={() => window.open('http://www.dailypop.kr/news/articleView.html?idxno=60946', '_blank', 'noopener noreferrer')}>
                    워너브러더스 코리아와 2022 DC 리그 오브 슈퍼-펫 런 개최
                </Title>
                <Content>
                    벤처기업 러닝포인트는 워너브러더스 코리아와 협업해 DC 리그 오브 슈퍼-펫 개봉기념을 맞이해 2022 DC 리그 오브 슈퍼-펫 런 비대면 마라톤 대회를 개최한다고 밝혔다.<br/>
                    이번 슈퍼-펫 런 대회는 브랜드와 함께하는 러닝포인트의 B급마라톤 대회로, 코로나19로 인해 비대면 방식으로 운영하는 대표적인 비대면 마라톤 대회...
                </Content>
                <From>
                데일리팝 · 2022.06.27
                </From>
            </News>
            <News>
                <Title onClick={() => window.open('http://www.kdpress.co.kr/news/articleView.html?idxno=110080', '_blank', 'noopener noreferrer')}>
                    러닝포인트, 삼양식품과 협업 ‘비대면 마라톤 불닭런’ 개최
                </Title>
                <Content>
                    스타트업 마라톤결제대행 플랫폼 러닝포인트는 국내 국내식품 브랜드 삼양식품과 협업해 ‘불닭런’을 개최한다고 밝혔다.<br/>
                    사회적 거리두기 상황 속에서도 시간과 장소에 구애받지 않고 애플리케이션을 통해 기록한 내용이나 사진을 인증하면 된다. 미션 중 하나...
                </Content>
                <From>
                    데일리경제 · 2022.01.19
                </From>
            </News>
            <News>
                <Title onClick={() => window.open('https://www.fnnews.com/news/202201060851439905', '_blank', 'noopener noreferrer')}>
                    스타트업 러닝포인트 동원F&#38;B MOU 체결
                </Title>
                <Content>
                    동원F&#38;B가 스타트업 '러닝포인트'와 환경·사회·지배구조(ESG) 실천을 위한 업무 협약을 체결했다고 밝혔다.<br/>
                    동원F&#38;B는 러닝포인트가 개최하는 비대면 기부 마라톤 대회 '애니멀런'의 공식 후원사로 참여해 매달 무라벨 친환경 생수...              
                </Content>
                <From>
                    파이낸셜뉴스 · 2022.01.06
                </From>
            </News>
            <News>
                <Title onClick={() => window.open('https://www.fnnews.com/news/202111111022015434', '_blank', 'noopener noreferrer')}>
                    동원F&#38;B - 러닝포인트, 기부 마라톤 '펭귄런' 개최
                </Title>
                <Content>
                    동원F&#38;B가 스타트업 '러닝포인트'와 협업해 멸종 위기 동물인 펭귄을 위한 비대면 기부 마라톤 대회 '펭귄런'을 개최한다고<br/>
                    11일 밝혔다. 동원F&#38;B는 멸종 위기종으로 지정된 펭귄에 대한 인식을 확대하기 위해 '펭귄런'을 기획했다. 현재 지구상의...
                </Content>
                <From>
                    파이낸셜 뉴스 · 2021.11.11
                </From>
            </News>
            <News>
                <Title onClick={() => window.open('http://www.dt.co.kr/contents.html?article_no=2021110402109932102005', '_blank', 'noopener noreferrer')}>
                    신세계百, 스타트업 손잡고 비대면 마라톤 개최
                </Title>
                <Content>
                    신세계백화점이 유기견 구호와 멸종위기 동물보호를 위한 비대면 마라톤을 개최한다. 신세계는 스타트업 러닝포인트와<br/>
                    손잡고 오는 13일부터 30일까지 반려동물 동반 비대면 마라톤을 개최한다고 4일 밝혔다. 러닝포인트는 지난해부터..
                </Content>
                <From>
                    디지털타임스 · 2021.11.04
                </From>
            </News>
            <News>
                <Title onClick={() => window.open('https://www.metroseoul.co.kr/article/20210809500314', '_blank', 'noopener noreferrer')}>
                    켈로그X애니멀런 '켈로그 프로틴런' 개최…비대면으로 기부까지                
                </Title>
                <Content>
                    농심켈로그가 국내 비대면 마라톤 브랜드 애니멀런과 협업해 '켈로그 프로틴런'을 개최한다고 9일 전했다.'켈로그 프로틴런'은<br/>
                    오는 8월 21일부터 열흘 간 에너지와 탄력을 충전하는 미션 중 하나를 달성하는 비대면 레이스다. 사회적 거리두기 상황...
                </Content>
                <From>
                    메트로 · 2021.08.09
                </From>
            </News>
            <br/><br/><br/><br/><br/><br/>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    // height: 1043px;
    background-color: rgba(239, 248, 252, 1);
    display: flex;  
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    @media screen and (max-width: 1100px){
        // height: 2000px;
    }
`
const News = styled.div`
    width: 800px;
    margin: 20px auto;
    @media screen and (min-width: 414px) and (max-width: 1100px){
        width: 800px;
    }
    @media screen and (max-width: 414px){
        width: 90%;
    }
`
const Title = styled.div`
    font-size: 30px;
    font-weight: bold;
    color: rgba(0, 160, 233, 1);
    cursor: pointer;
    @media screen and (min-width: 414px) and (max-width: 1100px){
        font-size: 30px;
    }
    @media screen and (max-width: 414px){
        font-size: 45px;
    }
`
const Content = styled.p`
    font-size: 15px;
    color: rgba(80, 80, 80, 1);
    margin-top: 5px;
    @media screen and (min-width: 414px) and (max-width: 1100px){
        font-size: 30px;
    }
    @media screen and (max-width: 414px){
        font-size: 30px;
    }
`
const From = styled.div`
    font-style: 16px;
    color: rgba(128, 188, 216, 1);
    margin-top: 5px;
    @media screen and (max-width: 414px){
        font-size: 31px;
    }
`
export default Press;