import React, { useState } from 'react'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import './Product.css'
import { GiPlainArrow } from 'react-icons/gi'


const Product = () => {
    const [show, setShow] = useState(2)

    const showInfo = (index) => {
            setShow(index)
    }

    return (
        <>
        <Box sx={{ height: '300px' }}></Box>
            <Typography variant="h3" sx={{ textAlign: 'center' }}>
                Product
            </Typography>
            <Box sx={{ height: '100px' }}></Box>
        <Container id="product">
            <Titles>
                <li onClick={() => showInfo(1)} className={show === 1 ? "ranking-active" : "ranking-inactive"}>
                    <span>랭킹마라톤</span>
                </li>
                <li onClick={() => showInfo(2)} className={show === 2 ? "animal-active" : "animal-inactive"}>
                    <span>애니멀런</span>
                </li>
                <li onClick={() => showInfo(3)} className={show === 3 ? "medal-active" : "medal-inactive"}>
                    <span>메달마켓</span>                        
                </li>
                <li onClick={() => showInfo(4)} className={show === 4 ? "mooa-active" : "mooa-inactive"}>
                    <span>MOOA</span>                        
                </li>
            </Titles>
            {show === 1 ? 
            <ProductContainer>
                <Logo>
                    <RankingLogo onClick={() => window.open('http://rankingmarathon.com/', '_blank', 'noopener noreferrer')}/>
                </Logo>
                <Introduce>
                    <p>국내외 마라톤 정보와 마라톤 참여, 결제까지 한번에 가능한</p>
                    <p>국내 최초 마라톤 전문 플랫폼</p>
                </Introduce>
                <Detail>
                        <DetailTitle>
                            <p style={{fontWeight:"bold"}}>국내 최초<br/>마라톤 전문 플랫폼</p>
                        </DetailTitle>
                        <DetailRound />
                        <DetailContent>
                            <ul>
                                <li>
                                    <p>국내외 마라톤 정보 최다 제공<br/>자연스러운 유입회원 창출</p>
                                </li>
                                <li><Arrow/></li>
                                <li>
                                    <p>국내외 마라톤 정보 최다 제공<br/>자연스러운 유입회원 창출<br/>마라톤대회 참여를 위한 결제플랫폼 제공<br/>한번에 참여할 수 있도록 유도</p>
                                </li>
                            </ul>
                        </DetailContent>
                        
                </Detail>
            </ProductContainer>
            : null }
            {show === 2 ? 
            <ProductContainer>
                <Logo>
                    <AnimalLogo onClick={() => window.open('https://www.animallace.com/', '_blank', 'noopener noreferrer')}/>
                </Logo>
                <Introduce>
                    <p>자기개발(운동)과 멸종위기동물 보호라는 가치실현으로</p>
                    <p>주목 받고 있는 국내 최다 개최 비대면 마라톤 브랜</p>
                </Introduce>
                <Detail>
                    <DetailTitle>
                        <p>매월 <b>멸종위기 동물을 소개</b><br/>해당동물 테마의<br/> <b>비대면 마라톤 개최</b></p>
                    </DetailTitle>
                    <DetailRound />
                    <AnimalDetailContent>
                        <ul>
                            <li>
                                <p>판매금액의 10%를<br/>자연보전기관 WWF(세계자연기금)에 기부</p>
                            </li>
                            <li><Arrow /></li>
                            <li>
                                <p>WWF의 기업리뷰를 통해<br/>정식후원기업으로 지정됨</p>
                            </li>
                        </ul>
                    </AnimalDetailContent>
                </Detail>
            </ProductContainer>
            : null }
            {show === 3 ? 
            <ProductContainer>
                <Logo>
                    <MedalLogo onClick={() => window.open('http://www.medalmarket.net/', '_blank', 'noopener noreferrer')}/>
                </Logo>
                <Introduce>
                    <p>마라톤 메달디자인부터 메달제작까지</p>
                    <p>마라톤의 중심 메달마켓</p>
                </Introduce>
                <Detail>
                    <DetailTitle>
                        <p>마라톤 컨셉에 맞는<br/>메달 디자인부터 제작까지<br/><b>메달 전문 브랜드</b></p>
                    </DetailTitle>
                    <DetailRound />
                    <DetailContent>
                        <ul>
                            <li>
                                <p>메달에 대한 컨셉과<br/>구현하고 싶은 이미지를 메달에 알맞게<br/>그래픽 시안 전달</p>
                            </li>
                            <li><Arrow /></li>
                            <li>
                                <p>공장 직접생산으로 합리적인 금액으로<br/>금형 제작 부터 개별포장까지<br/>전문적인 메달 제작이 가능</p>
                            </li>
                        </ul>
                    </DetailContent>
                </Detail>
            </ProductContainer>
            : null }
            {show === 4 ? 
            <ProductContainer>
                <Logo>
                    <MooaLogo onClick={() => window.open('http://artmooa.com', '_blank', 'noopener noreferrer')}/>
                </Logo>
                <Introduce>
                    <p>모든 그림을 모아 MOOA</p>
                    <p>모든 그림을 모아 MOOA</p>
                </Introduce>
                <Detail>
                    <DetailTitle>
                        <p>자신이 그린 작품을<br/>공유 및 판매까지<br/><b>미술 전문 작품 브랜드</b></p>
                    </DetailTitle>
                    <DetailRound />
                    <DetailContent>
                        <ul>
                            <li>
                                <p>나의 그림을<br/>다른 사람과 공유하고 싶다</p>
                            </li>
                            <li><Arrow /></li>
                            <li>
                                <p>나의 그림을<br/>다른 사람에게 판매하고 싶다</p>
                            </li>
                        </ul>
                    </DetailContent>
                </Detail>
            </ProductContainer>
            : null }
        </Container>
        </>
    )
}

const Container = styled.div`
    width: 100%;
    height: 1800px;
    display: flex;
    flex-direction: column;
    padding: 200px 0;
    @media screen and (max-width: 1100px){
        padding: 100px 0;
    }
`
const Titles = styled.ul`
    width: 850px;
    display: flex;
    margin: 0 auto;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1100px){
        width: 90%;
    }
    li{
        width: 170px;
        height: 45px;
        line-height: 40px;
        font-size: 20px;
        cursor: pointer;
        @media screen and (max-width: 1100px){
        width: 250px;
        height: 60px;
        font-size: 40px;
        font-weight: bold;
        
    }
    }
`
const ProductContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    @media screen and (max-width: 1100px){

    }
`
const Logo = styled.div`
    height: 300px;
`
const RankingLogo = styled.div`
    background: url('/images/RankingLogo.png') no-repeat;
    background-size: 100% 100%;
    width: 250px;
    height: 150px;
    margin-bottom: 100px;
    cursor: pointer;
    @media screen and (max-width: 1100px){
        width: 350px;
        height: 250px;
    }
`
const AnimalLogo = styled.div`
    background: url('/images/animal-logo.png') no-repeat;
    background-size: 100% 100%;
    width: 200px;
    height: 120px;
    margin-bottom: 100px;
    cursor: pointer;
    @media screen and (max-width: 1100px){
        width: 300px;
        height: 220px;
    }
`
const MedalLogo = styled.div`
    background: url('/images/medalLogo.png') no-repeat;
    background-size: 100% 100%;
    width: 169px;
    height: 191px;
    margin-bottom: 100px;
    cursor: pointer;
    @media screen and (max-width: 1100px){
        width: 269px;
        height: 291px;

    }
`
const MooaLogo = styled.div`
    background: url('/images/mooa-logo.png') no-repeat;
    background-size: 100% 100%;
    width: 191px;
    height: 191px;
    margin-bottom: 100px;
    cursor: pointer;
    @media screen and (max-width: 1100px){
        width: 269px;
        height: 291px;

    }
`
const Introduce = styled.div`
    text-align: center;
    margin-bottom: 100px;
    p{
        font-size: 20px;
        @media screen and (max-width: 1100px){
            font-size: 37px;
        }
    }
`
const Detail = styled.div`
`
const DetailTitle = styled.div`
    position: relative;
    right: 200px;
    width: 450px;
    height: 450px;
    border: 12px solid rgba(0, 160, 233, 1);
    border-radius: 100%;
    @media screen and (max-width: 1100px){
        width: 500px;
        height: 500px;
    }
    p{
        position: relative;
        text-align: center;
        top: 170px;
        color: #000000;
        font-size: 24px;
        @media screen and (max-width: 1100px){
            font-size: 37px;
            top: 150px;
        }
    }
`
const DetailRound = styled.div`
    position: relative;
    top: -450px;
    left: 180px;
    width: 450px;
    height: 450px;
    background-color: rgba(0, 160, 233, 1);
    opacity: 0.3;
    border-radius: 100%;
    @media screen and (max-width: 1100px){
        width: 500px;
        height: 500px;
        position: relative;
        top: -500px;
    }
`
const DetailContent = styled.div`
    position: relative;
    top: -800px;
    left: 180px;
    
    ul{
        list-style: none;
        font-size: 20px;
        text-align: center;
        @media screen and (max-width: 1100px){
            font-size: 25px;
        }
    }
    li{
        margin: 10px 0;
    }
    @media screen and (max-width: 1100px){
        position: relative;
        top: -920px;
    }
`
const AnimalDetailContent = styled.div`
    position: relative;
    top: -770px;
    left: 180px;
    @media screen and (max-width: 1100px){
        position: relative;
        top: -870px;
    }
    ul{
        list-style: none;
        font-size: 20px;
        text-align: center;
        @media screen and (max-width: 1100px){
            font-size: 25px;
        }
    }
    li{
        margin: 10px 0;
    }
`
const Arrow = styled(GiPlainArrow)`
    color: rgba(0, 160, 233, 1);
    font-size: 40px;
`
export default Product
